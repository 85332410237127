// 导入收入合同
// popImportContract
<template>

    <div class="popImportContract">
        <el-dialog :title="title"
                   :close-on-click-modal="false"
                   :visible.sync="dialogVisible"
                   width="30%"
                   :before-close="close">
            <p class="download">
                <span>使用最新的模板文件导入</span>
                <com-file-upload-list fileBoxHeight="150px"
                                      :showUpBtn="false"
                                      :uploadData="{isTemplate: true, resourceType: resourceType}"
                                      :neddLoadFile="true"
                                      :justShowFistOne="false"
                                      :isNeedPopTemDownload="false"
                                      :showDownloadBtn="true"
                                      :showDelBtn="false"></com-file-upload-list>
            </p>
            <el-upload name="file"
                       :headers="configHeader"
                       :action="action"
                       :data="queryData"
                       :on-success="uploadSuccess"
                       :before-upload="beforeUpload"
                       :on-error="uploadError"
                       multiple
                       :show-file-list="false">
                <el-button type="primary"
                           v-loading="importLoading"
                           size="mini">上传文件</el-button>
            </el-upload>
        </el-dialog>

    </div>
</template>

<script>
import ComFileUploadList from "@/components/upload/comFileUploadList";

export default {
    name: "popImportContract",

    props: {
        // 模块名称 因为项目和实施比较特殊
        type: {
            type: String,
            default: null,
        },
    },

    components: {
        ComFileUploadList,
    },

    data() {
        return {
            dialogVisible: true,
            fileList: [], // 上传的文件
            downTempLoading: false, // 下载模板
            importLoading: false, // 上传文件
        };
    },

    created() {},

    mounted() {},

    methods: {
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
        // 刷新父页面列表
        resetlist(){
            this.$emit("resetlist");
        },
        //下载模板
        downTemplate() {
            this.commonJs.fileDownLoad({
                isTemplate: true,
                resourceType: "workTaskImportFile",
            });
        },
        //上传之前
        beforeUpload(file) {
            if (file.name.indexOf("xls") < 0 || file.name.indexOf("xlsx") < 0) {
                this.$message({
                    message: "文件格式错误，请检查后重新上传！",
                    type: "error",
                });
                return false;
            }
            let maxBit = 100 * 1024 * 1024;
            if (file.size > maxBit) {
                this.$message({
                    message: "超过文件上传上限，最大100M",
                    type: "warning",
                });
                return false;
            }
            this.importLoading = true;
        },
        // 上传成功
        uploadSuccess(e) {
            if (e.code === 200) {
                if (!e.content.importFileId) {
                    this.$confirm(
                        `
                    <p class="uploadRes"><i class="el-icon-success"></i>导入成功</p>
                    <p class="uploadResInfo">上传<span style="color: #67c23a;">成功</span>${e.content.rightNum}条数据</p>
                    `,
                        "",
                        {
                            confirmButtonText: "确定",
                            showCancelButton: false,
                            dangerouslyUseHTMLString: true,
                        }
                    );
                    this.importLoading = false;
                    this.resetlist();
                } else {
                    this.$confirm(
                        `
                    <p class="uploadRes"><i class="el-icon-warning"></i>是否查看失败原因？</p>
                    <p class="uploadResInfo">上传<span style="color: #67c23a;">成功</span>${e.content.rightNum}条数据</p>
                    <p class="uploadResInfo">上传<span style="color: #F56C6C;">失败</span>${e.content.errorNum}条数据</p>
                    `,
                        "",
                        {
                            confirmButtonText: "下载失败原因",
                            dangerouslyUseHTMLString: true,
                        }
                    ).then(() => {
                        this.commonJs.fileDownLoad({
                            id: e.content.importFileId,
                            name: `${this.title}失败.xlsx`,
                        });
                        /* this.commonJs.fileDownLoad({id: e.content.importFileId, name: "导入收入合同失败.xlsx"}); */
                    });
                    this.importLoading = false;
                    this.resetlist();
                }
            } else {
                this.uploadError(e);
            }
        },
        // 上传失败
        uploadError(e) {
            this.$message({
                message: e.message || "网络错误，请稍后重试。",
                type: "error",
            });
            this.importLoading = false;
        },
    },

    computed: {
        configHeader() {
            return { "access-token": this.commonJs.getCookie("ERPToken") };
        },

        action() {
            switch (this.type) {
                case "incomeContract":
                    return (
                        window.configPath.baseUrl +
                        "/contract/contract/importExcel"
                    );
                case "payContract":
                    return (
                        window.configPath.baseUrl +
                        "/contract/contract/importExcel"
                    );
                case "payOther":
                    return (
                        window.configPath.baseUrl +
                        "/income/expenses-other/importExcel"
                    );
                default:
                    return "";
            }
        },
        queryData() {
            switch (this.type) {
                // 收入合同
                case "incomeContract":
                    return {
                        contractType: "INCOME_CONTRACT",
                    };
                // 支出合同
                case "payContract":
                    return {
                        contractType: "PAY_CONTRACT",
                    };
                // 其他支出导入
                case "payOther":
                    return {};

                default:
                    return "";
            }
        },

        title() {
            switch (this.type) {
                case "incomeContract":
                    return "导入收入合同";
                case "payContract":
                    return "导入支出合同";
                case "payOther":
                    return "导入其他支出";
                default:
                    return "";
            }
        },
        resourceType() {
            switch (this.type) {
                case "incomeContract":
                    return "incomeContractImportFile";
                case "payContract":
                    return "expenseContractImportFile";
                case "payOther":
                    return "expenseOtherImportFile";
                default:
                    return "";
            }
        },
    },

    watch: {},
};
</script>

<style lang="scss" scoped>
.download span {
    vertical-align: middle;
}
.download .el-link {
    margin: 0 2px;
}
</style>
<style lang="scss">
.el-message-box ::v-deep .uploadRes {
    font-weight: 600;
    font-size: 15px;
    line-height: 30px;
}

.el-message-box ::v-deep .uploadRes .el-icon-success,
.el-message-box ::v-deep .uploadRes .el-icon-warning {
    color: #67c23a;
    font-size: 24px;
    vertical-align: middle;
    margin-right: 5px;
}
.el-message-box ::v-deep .uploadRes .el-icon-warning {
    color: #e6a23c;
}
.uploadResInfo {
    padding-left: 28px;
}
</style>