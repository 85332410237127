// 收入支出管理列表
<template>

    <div class="incomePayManage baseBg">

        <div class="listBox mainWhiteBg">
            <el-tabs v-model="searchForm.status"
                     ref="searchTab"
                     class="searchTab"
                     @tab-click="getList()">
                <el-tab-pane name="ALL"
                             v-if="commonJs.isShowBtn(detailRole, `Tab_${routeName==='合同收入' ? '收入' : '支出'}管理_${routeName}_全部`)">
                    <div class="tabsLabel count"
                         @click="commonJs.setTabId(detailRole,`Tab_${routeName==='合同收入' ? '收入' : '支出'}管理_${routeName}_全部`)"
                         slot="label">
                        <el-badge :type="activeName == 'ALL' ? 'primary' : 'warning'"
                                  :value="counts.ALL"
                                  class="item">
                            全部
                        </el-badge>
                    </div>
                </el-tab-pane>
                <el-tab-pane name="AUDITING"
                             v-if="commonJs.isShowBtn(detailRole, `Tab_${routeName==='合同收入' ? '收入' : '支出'}管理_${routeName}_待审批`)">
                    <div class="tabsLabel count"
                         @click="commonJs.setTabId(detailRole,`Tab_${routeName==='合同收入' ? '收入' : '支出'}管理_${routeName}_待审批`)"
                         slot="label">
                        <el-badge :type="activeName == 'AUDITING' ? 'primary' : 'warning'"
                                  :value="counts.AUDITING"
                                  class="item">
                            待审批
                        </el-badge>
                    </div>
                </el-tab-pane>
                <el-tab-pane name="EXECUTING"
                             v-if="commonJs.isShowBtn(detailRole, `Tab_${routeName==='合同收入' ? '收入' : '支出'}管理_${routeName}_待${routeName === '合同收入' ? '收款' : '付款'}`)">
                    <div class="tabsLabel count"
                         @click="commonJs.setTabId(detailRole,`Tab_${routeName==='合同收入' ? '收入' : '支出'}管理_${routeName}_待${routeName === '合同收入' ? '收款' : '付款'}`)"
                         slot="label">
                        <el-badge :type="activeName == 'EXECUTING' ? 'primary' : 'warning'"
                                  :value="counts.EXECUTING"
                                  class="item">
                            待{{routeName === "合同收入" ? "收款" : "付款"}}
                        </el-badge>
                    </div>
                </el-tab-pane>
                <el-tab-pane name="DISTRIBUTION"
                             v-if="commonJs.isShowBtn(detailRole, `Tab_${routeName==='合同收入' ? '收入' : '支出'}管理_${routeName}_待分配`)">
                    <div class="tabsLabel count"
                         @click="commonJs.setTabId(detailRole,`Tab_${routeName==='合同收入' ? '收入' : '支出'}管理_${routeName}_待分配`)"
                         slot="label">
                        <el-badge :type="activeName == 'DISTRIBUTION' ? 'primary' : 'warning'"
                                  :value="counts.DISTRIBUTION"
                                  class="item">
                            待分配
                        </el-badge>
                    </div>
                </el-tab-pane>
                <el-tab-pane name="FINISH"
                             v-if="commonJs.isShowBtn(detailRole, `Tab_${routeName==='合同收入' ? '收入' : '支出'}管理_${routeName}_完成`)">
                    <div class="tabsLabel count"
                         @click="commonJs.setTabId(detailRole,`Tab_${routeName==='合同收入' ? '收入' : '支出'}管理_${routeName}_完成`)"
                         slot="label">
                        完成
                    </div>
                </el-tab-pane>
            </el-tabs>
            <div class="tableBox">
                <div class="greySearchBar">
                    <el-form :model="searchForm"
                             ref="searchForm"
                             :rules="searchFormRules"
                             :inline="true">
                        <!-- TODO 新增合同编号查询 -->
                        <el-form-item label="合同编号"
                                      prop="code">
                            <el-input class="width150"
                                      v-model="searchForm.code"
                                      placeholder="请输入合同编号"
                                      clearable />
                        </el-form-item>
                        <el-form-item label="合同名称"
                                      prop="name">
                            <el-input class="width150"
                                      v-model="searchForm.name"
                                      placeholder="请输入合同名称"
                                      clearable />
                        </el-form-item>

                        <el-form-item label="项目客户"
                                      prop="companyId">
                            <cust-sup-choose placeholderStr="请选择客户/供应商"
                                             :showSeeProvider="false"
                                             :disable="true"
                                             :shouldClean="true"
                                             :isScorll="true"
                                             @handClear="clearProvider"
                                             :cleanProvider="cleanProvider"
                                             @treeSelectNodeClick="customerClick"></cust-sup-choose>
                        </el-form-item>

                        <el-form-item label="合同类型"
                                      prop="category"
                                      v-if="routeName === '合同收入'">
                            <el-select class="width150"
                                       v-model="searchForm.category"
                                       placeholder="请选择合同类型">
                                <el-option v-for="item in options"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="部门"
                                      prop="deptId">
                            <dept-select-tree class="width150"
                                              placeholderStr="请选择部门"
                                              :needClean="needClean"
                                              :shouldClean="true"
                                              @handClear="clearDept"
                                              @treeSelectNodeClick="departmentClick"></dept-select-tree>
                        </el-form-item>

                        <el-form-item v-if="type==='doing'"
                                      label="非质保金收款情况"
                                      prop="normalIsFinish">
                            <el-select class="width150"
                                       v-model="searchForm.normalIsFinish"
                                       placeholder="请选择">
                                <el-option v-for="item in quality"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-button type="primary"
                                   @click="getList">搜索</el-button>
                        <el-button @click="resetSearchForm">清除</el-button>
                    </el-form>
                </div>

                <div class="greySearchBar control">
                    <com-export-table-to-excel :searchForm="searchForm"></com-export-table-to-excel>
                    <el-button type="primary"
                               v-if="routeName === '合同收入'"
                               size="mini"
                               @click="openpopImportContract('incomeContract')">导入</el-button>
                    <el-button type="primary"
                               v-else
                               size="mini"
                               @click="openpopImportContract('payContract')">导入</el-button>
                    <el-button type="primary"
                               v-if="routeName === '合同收入'"
                               size="mini"
                               @click="addNewContract('THIRDPART_CONTRACT')">新增代中标合同</el-button>
                    <el-button type="success"
                               size="mini"
                               @click="addNewContract()">新增合同</el-button>
                </div>

                <div class="tableBox">
                    <el-table :data="tableData.data"
                              border
                              ref="table"
                              v-loading="isloading"
                              :height="tableHeight"
                              stripe>
                        <el-table-column label="序号"
                                         type="index"
                                         align="center"
                                         width="60">
                        </el-table-column>
                        <el-table-column prop="code"
                                         label="合同编号"
                                         align="left"
                                         min-width="auto"
                                         width="160">
                        </el-table-column>
                        <el-table-column prop="name"
                                         label="合同名称"
                                         align="left"
                                         min-width="auto"
                                         width="120">
                        </el-table-column>
                        <el-table-column prop="category.text"
                                         label="合同类型"
                                         align="left"
                                         min-width="auto"
                                         width="120">
                        </el-table-column>
                        <!-- <el-table-column prop="type.text"
                                         label="合同类型"
                                         align="left"
                                         v-else
                                         min-width="auto"
                                         width="100">
                        </el-table-column> -->

                        <el-table-column prop="companyName"
                                         label="客户"
                                         align="left"
                                         min-width="auto"
                                         width="150">
                        </el-table-column>
                        <el-table-column prop="totalMoney"
                                         label="合计金额（元）"
                                         align="left"
                                         min-width="auto"
                                         width="120">
                        </el-table-column>
                        <el-table-column prop="totalMoney"
                                         v-if="searchForm.status === 'EXECUTING'&&routeName==='合同支出'"
                                         label="已结算金额/待结算金额（元）"
                                         align="left"
                                         min-width="auto"
                                         width="120">
                                         <template slot-scope="scope">
                                           {{scope.row.settledMoney}}/{{scope.row.balanceSettleMoney}}
                                         </template>
                        </el-table-column>
                        <el-table-column prop="actualAmount"
                                         :label="`实际应${routeName==='合同收入' ? '收' : '付'}款金额（元）`"
                                         align="left"
                                         min-width="auto"
                                         width="100">
                        </el-table-column>

                        <el-table-column v-for="col in tableData.columns"
                                         :prop="col.id"
                                         :key="col.id"
                                         :label="col.label"
                                         align="left"
                                         :width="col.width"
                                         :min-width="col.minWdith || 50">
                        </el-table-column>

                        <el-table-column prop="memo"
                                         key="memo"
                                         label="备注"
                                         align="left"
                                         width="120">
                            <template slot-scope="scope">
                                <el-tooltip class="item"
                                            effect="dark"
                                            :content="scope.row.memo || '暂无'"
                                            placement="left">
                                    <span class="textOverflow">{{scope.row.memo || '暂无'}}</span>
                                </el-tooltip>
                            </template>
                        </el-table-column>

                        <el-table-column prop="status.text"
                                         key="status.text"
                                         label="状态"
                                         align="left"
                                         width="70">
                        </el-table-column>
                        <el-table-column prop="id"
                                         label="操作"
                                         align="left"
                                         fixed="right"
                                         width="100">
                            <template slot-scope="scope">
                                <el-button type="primary"
                                           size="mini"
                                           @click="toDetails(scope.row)">查看</el-button>
                                <template v-if="scope.row.category && scope.row.category.name !== 'category'">
                                    <!-- 只有草稿和驳回可以删除 -->
                                    <template v-if="(scope.row.status && scope.row.status.name) && scope.row.status.name === 'START' || scope.row.status.name === 'REJECTED'">
                                        <el-popconfirm confirm-button-text="删除"
                                                       cancel-button-text="取消"
                                                       icon="el-icon-info"
                                                       icon-color="red"
                                                       @confirm="del(scope.row.id)"
                                                       title="真的要删除吗？">
                                            <el-button type="danger"
                                                       size="mini"
                                                       v-if="!scope.row.earlyFlag"
                                                       slot="reference">删除</el-button>
                                        </el-popconfirm>
                                    </template>
                                </template>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="tableStatisticsBar"
                         v-if="contractAmount">
                        <template v-if="routeName.indexOf('收入') !== -1">
                            <span>合同总金额：{{commonJs.toThousands(contractAmount.totalMoney)}} &nbsp;&nbsp;</span>
                            <span>收款总金额：{{commonJs.toThousands(contractAmount.actualAmount)}} &nbsp;&nbsp;</span>
                            <span>已收款金额：{{commonJs.toThousands(contractAmount.receiptPaidAmount)}} &nbsp;&nbsp;</span>
                            <span>应收余额：{{commonJs.toThousands(contractAmount.balance)}} &nbsp;&nbsp;</span>
                            <span>已开票金额：{{commonJs.toThousands(contractAmount.invoiceAmount)}} &nbsp;&nbsp;</span>
                        </template>
                        <template v-else>
                            <span>付款总金额：{{commonJs.toThousands(contractAmount.actualAmount)}} &nbsp;&nbsp;</span>
                            <span>已付款金额：{{commonJs.toThousands(contractAmount.receiptPaidAmount)}} &nbsp;&nbsp;</span>
                            <span>应付余额：{{commonJs.toThousands(contractAmount.balance)}} &nbsp;&nbsp;</span>
                            <span>已收票金额：{{commonJs.toThousands(contractAmount.invoiceAmount)}} &nbsp;&nbsp;</span>
                        </template>
                    </div>
                    <!-- 分页 -->
                    <Pagination :page="searchForm.page"
                                :limit="searchForm.size"
                                :total="pageTotal"
                                @pagination="paginationChange" />

                </div>

            </div>
        </div>
        <!-- 导入收入支出 -->
        <pop-import-contract v-if="popImportContractVisible"
                             :type="popImportType"
                             @resetlist="resetlist"
                             @close="closepopImportContrac"></pop-import-contract>
    </div>

</template>

<script>
import ComExportTableToExcel from "../../components/export/comExportTableToExcel";
import DeptSelectTree from "../../components/selectTree/deptSelectTree";
import CustSupChoose from "../../components/selectTree/custSupChoose";
import { Loading } from "element-ui";
import Pagination from "@/components/Pagination"; // 分页
import contractManageApi from "@/api/contractManageApi.js";
import PopImportContract from "./components/popImportContract.vue";
export default {
    name: "incomePayManage",

    props: {},

    components: {
        ComExportTableToExcel,
        DeptSelectTree,
        CustSupChoose,
        Pagination,
        PopImportContract,
    },

    data() {
        return {
            isloading: false,
            searchForm: {
                status: "", // tabs 1=待审批,2=已审批,3=我发起的,4=抄送我的
                name: "", //合同名称
                code: "", // 合同编号
                companyId: "", //客户或供应商id
                type: "", //合同类型
                deptId: "", //部门id
                page: 1,
                size: 20,
            },
            searchFormRules: {},
            pageTotal: 0,

            tableData: {
                data: [],
                columns: [
                    // 注释掉的是写页面时还有，对接时原型中没了
                    // { id: "", label: "已收款金额（元）", width: "100" },
                    {
                        id: "contractProjectName",
                        label: "关联项目",
                        width: "120",
                        minWidth: "auto",
                    },
                    // {
                    //     id: "",
                    //     label: "要求开票金额/实际开票金额",
                    //     width: "100",
                    // },
                    // { id: "", label: "非质保金收款情况", width: "100" },
                    // { id: "waitMoney", label: "待分配金额（元）", width: "100" },
                    { id: "deptName", label: "部门", width: "80" },
                    { id: "chargeUserName", label: "负责人", width: "90" },
                    { id: "createTimeStr", label: "新增日期", width: "130" },
                    {
                        id: "updateTimeStr",
                        label: "最近修改日期",
                        width: "130",
                    },
                    // { id: "memo", label: "备注", width: "120" },
                    // { id: "status.text", label: "状态", width: "60" },
                ],
            },
            // tabs对应文字
            tabsName: ["全部", "待审批", "待收款", "待分配", "完成"],
            counts: {
                ALL: 0, // 全部 数量
                AUDITING: 0, // 待审批 数量
                EXECUTING: 0, // 待收款 数量
                DISTRIBUTION: 0, // 待分配 数量
                FINISH: 0, // 完成 数量
            },

            options: [
                {
                    value: "",
                    label: "全部",
                },
                {
                    value: "COMMON_CONTRACT",
                    label: "普通合同",
                },
                {
                    value: "THIRDPART_CONTRACT",
                    label: "代中标合同",
                },
                {
                    value: "EARLY_CONTRACT",
                    label: "期初合同",
                },
            ],

            quality: [
                {
                    value: "",
                    label: "全部",
                },
                {
                    value: "1",
                    label: "已完成",
                },
                {
                    value: "0",
                    label: "未完成",
                },
            ],
            cleanProvider: false, //清空客户供应商
            detailRole: {}, //权限数据
            contractAmount: null, // 列表统计金额
            popImportContractVisible: false, // 导入合同收入弹窗显示
            popImportType: "", //导入合同收入弹窗type
        };
    },

    created() {},

    mounted() {
        this.commonJs.getFistTabId(this.detailRole, [
            `Tab_${this.routeName === "合同收入" ? "收入" : "支出"}管理_${
                this.routeName
            }_全部`,
            `Tab_${this.routeName === "合同收入" ? "收入" : "支出"}管理_${
                this.routeName
            }_待审批`,
            `Tab_${this.routeName === "合同收入" ? "收入" : "支出"}管理_${
                this.routeName
            }_待${this.routeName === "合同收入" ? "收款" : "付款"}`,
            `Tab_${this.routeName === "合同收入" ? "收入" : "支出"}管理_${
                this.routeName
            }_待分配`,
            `Tab_${this.routeName === "合同收入" ? "收入" : "支出"}管理_${
                this.routeName
            }_完成`,
        ]);
        // 选中第一个tab
        this.searchForm.status = this.$refs.searchTab.$children[1].name;
        if (this.routeName === "合同支出") {
            this.searchForm.type = "PAY_CONTRACT";
        } else {
            this.searchForm.type = "INCOME_CONTRACT";
        }
        // 合同管理-列表数量
        this.getContractCount();
        // 获取列表
        this.getList();
    },

    methods: {
        // 获取列表金额统计
        getContractAmount() {
            let loading = Loading.service({
                target: document.querySelector(".tableBox"),
            });
            let data = {
                type:
                    this.routeName === "合同收入"
                        ? "INCOME_CONTRACT"
                        : "PAY_CONTRACT", // 类别类型(PAY_CONTRACT[支出],INCOME_CONTRACT[收入]))
                status: this.searchForm.status, // 合同状态(ALL('全部') REJECTED('驳回') AUDITING('待审批') EXECUTING('执行中') DISTRIBUTION('待分配') FINISH('已完成'))
            };
            contractManageApi
                .getContractAmount(data)
                .then((res) => {
                    loading.close();
                    this.contractAmount = res.content;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 合同管理-列表数量
        getContractCount() {
            let loading = Loading.service({
                target: document.querySelector(".el-tabs"),
            });
            contractManageApi
                .getContractCount(
                    this.routeName === "合同收入"
                        ? "INCOME_CONTRACT"
                        : "PAY_CONTRACT"
                )
                .then((res) => {
                    this.counts = {
                        ALL: res.content.allCount, // 初拟 数量
                        AUDITING: res.content.auditingCount, // 待审批 数量
                        EXECUTING: res.content.executingCount, // 待收款 数量
                        DISTRIBUTION: res.content.distributionCount, // 待分配 数量
                        FINISH: res.content.finishCount, // 完成 数量
                    };
                    loading.close();
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 删除合同
        del(id) {
            this.isloading = true;
            contractManageApi
                .deleteContractTable(id)
                .then(() => {
                    this.$message({ message: "操作成功！", type: "success" });
                    /* 获取列表 */
                    this.getList();
                    this.isloading = false;
                })
                .catch(() => {
                    this.isloading = false;
                });
        },
        /* 获取列表 */
        getList() {
            if (window.localStorage.getItem("tabId") === "null") {
                return;
            }

            this.isloading = true;
            let data = Object.assign(this.searchForm);
            contractManageApi
                .getContractTable(data)
                .then((res) => {
                    this.isloading = false;
                    this.tableData.data = res.content.records;
                    this.pageTotal = res.content.total;
                    this.counts[this.searchForm.status] = res.content.total;
                    // 获取列表金额统计
                    this.getContractAmount();
                })
                .catch(() => {
                    this.isloading = false;
                })
                .finally(() => {
                    this.$nextTick(() => {
                        this.$refs.table.doLayout();
                    });
                });
        },
        // 分页改变
        paginationChange(e) {
            this.searchForm.size = e.size;
            this.searchForm.page = e.page;
            this.getList();
        },
        //部门的选择
        departmentClick(node) {
            this.searchForm.deptId = node.id;
        },
        //部门清空
        clearDept() {
            this.searchForm.deptId = "";
        },
        // 客户的选择
        customerClick(node) {
            this.searchForm.companyId = node.id;
        },
        // 客户的清空
        clearProvider() {
            this.searchForm.companyId = "";
        },
        // 清空表单
        resetSearchForm() {
            this.$nextTick(() => {
                if (this.$refs["searchForm"] !== undefined) {
                    this.$refs["searchForm"].resetFields();
                    this.searchForm.initiatorId = null; // 清空发起人
                    //部门清空
                    this.clearDept();
                    this.clearProvider();
                    this.needClean = Math.random();
                    this.cleanProvider = Math.random();
                    // 查询列表
                    this.getList();
                }
            });
        },
        // 导入 打开弹框
        openpopImportContract(type) {
            this.popImportType = type;
            this.popImportContractVisible = true;
        },
        // 导入 关闭弹框
        closepopImportContrac() {
            this.popImportContractVisible = false;
        },
        // 导入 刷新列表页面
        resetlist() {
            this.getList();
        },
        // 新增合同 type默认为普通合同
        addNewContract(type = "COMMON_CONTRACT") {
            this.$router.push({
                path: "/incomeManage/contractDetail",
                query: {
                    type,
                    payOrIncommon:
                        this.routeName === "合同收入"
                            ? "INCOME_CONTRACT"
                            : "PAY_CONTRACT",
                },
            });
        },
        // 跳转到合同详情
        toDetails(data) {
            this.$router.push({
                path: "/incomeManage/contractDetail",
                query: {
                    id: data.id,
                    activeName: this.searchForm.status,
                },
            });
        },
    },

    /* 组件缓存触发 */
    activated() {
        this.getList();
    },

    computed: {
        /**
         * 根据路由判断页面
         * contractIncome == 合同收入, contractPay == 合同支出
         */
        routeName() {
            let routeInfo = {
                contractIncome: "合同收入",
                contractPay: "合同支出",
            };
            return routeInfo[this.$route.name] || null;
        },

        tableHeight() {
            return this.commonJs.getTableHeight({ pageOtherHeight: 360 });
        },

        //权限数据
        roleInfo() {
            return this.$store.state.role.roleInfo;
        },
    },

    watch: {
        roleInfo: {
            deep: true,
            immediate: true,
            handler: function (val) {
                this.detailRole = this.commonJs.tabIsAlive(
                    val,
                    `${this.routeName}_列表`
                );
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.textOverflow {
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
}
.pagination-container {
    margin-top: 0;
}
</style>
