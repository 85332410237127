// import request from '@/utils/request'
import {
    get,
    // post,
    // postForm,
    postJson,
} from "./http/http";

const contractManageApi = {
    // 查询合同金额统计数量
    getContractAmount(params) {
        return get(`/contract/contract/statics`, params);
    },
    // 查询合同列表统计数量
    getContractCount(type) {
        return get(`/contract/contract/count/${type}`);
    },
    // 查询合同列表
    getContractTable(params) {
        return postJson("/contract/contract/table", params);
    },
    // 查询合同下拉
    getContractOpts(params) {
        return get(`/contract/contract/selectList`, params);
    },

    // 删除合同
    deleteContractTable(id) {
        return get(`/contract/contract/delete/${id}`);
    },
    // 撤销合同
    rebackContract(id) {
        return get(`/contract/contract/withdraw/${id}`);
    },
    // 取消完成合同
    cancelComplete(id) {
        return get(`/contract/contract/cancel/finish/${id}`);
    },

    //获取自动生成合同编号
    createAutoContractCode(params) {
        return get(`/contract/contract/autoCode`, params);
    },

    //查询合同关联项目列表清单根据合同id
    getByContractId(id) {
        return get(
            `/contract/project-contract-relevance/findByContractId/${id}`
        );
    },

    //查询未完成的项目列表数据
    getUndoProject(params) {
        return postJson(
            `/contract/project-contract-relevance/findProject`,
            params
        );
    },

    //保存合同关联项目数据
    saveUndoProjectBatch(params) {
        return postJson(
            `/contract/project-contract-relevance/saveBatch`,
            params
        );
    },

    //删除合同关联项目
    deleteConnectProject(id) {
        return get(`/contract/project-contract-relevance/delete/${id}`);
    },

    //修改合同关联项目所占金额
    saveUndoProject(params) {
        return postJson(`/contract/project-contract-relevance/save`, params);
    },

    //查看合同下的付款、收款计划列表
    getFinancePlanList(params) {
        return get(`/contract/finance-plan/findFinancePlanList`, params);
    },

    //保存付款/收款计划
    saveFinancePlanInfo(params) {
        return postJson(`/contract/finance-plan/save`, params);
    },

    //删除付款/收款计划
    deleteFinancePlanInfo(id) {
        return get(`/contract/finance-plan/delete/${id}`);
    },

    //查看合同下的付款、收款记录
    getByContractCollRecord(params) {
        return get(`/contract/finance-record/findByContractId`, params);
    },

    //删除付款、收款记录项目
    deleteCollRecord(id) {
        return get(`/contract/finance-record/delete/${id}`);
    },

    //保存付款、收款记录数据
    saveCollRecord(params) {
        return postJson(`/contract/finance-record/save`, params);
    },

    //保存合同
    saveContract(params) {
        return postJson(`/contract/contract/save`, params);
    },

    // 查询合同详情
    getContractDetail(id) {
        return get(`/contract/contract/findById/${id}`);
    },

    // 合同生效
    effectContract(params) {
        return postJson(`/contract/contract/take/effect`, params);
    },

    // 查询付款、收款记录详情
    getCollRecordDetail(id) {
        return get(`/contract/finance-record/findById/${id}`);
    },

    // 查询付款计划详情
    getCollPlanDetail(id) {
        return get(`/contract/finance-plan/findById/${id}`);
    },

    //合同从执行中到待分配状态
    contractUpdateNextStatus(params) {
        return postJson(`/contract/contract/finishContract`, params);
    },

    // 完成合同
    completeContract(id) {
        return postJson(`/contract/contract/finish/${id}`);
    },

    //合同从待分配状态到记录状态
    endContract(params) {
        return get(`/contract/contract/endContract`, params);
    },

    //上传合同附件
    uploadContractFile(params) {
        return get(`/contract/contract/upload-contract-attachment`, params);
    },

    /**
     * ------------------------------ 其他收入 ------------------------------
     */
    // 查询其他金额统计数量
    getOtherAmount(params) {
        return get(`/income/expenses-other/statics`, params);
    },
    // 其他收入|支出列表计数
    otherListCount(type) {
        return get(`/income/expenses-other/count/${type}`);
    },
    // 其他收入|支出列表
    getOtherList(params) {
        return postJson(`/income/expenses-other/table`, params);
    },
    // 其他收入|支出 保存
    saveOtherIncome(params) {
        return postJson(`/income/expenses-other/save`, params);
    },
    // 获取收入类别下拉
    getIncomeTypeOpts(type) {
        return get(`/category/getSelectTree/${type}`);
    },
    // 查询其他收入|支出 详情
    getOtherPayDetails(id) {
        return get(`/income/expenses-other/detail/${id}`);
    },
    // 查询收款管理详情
    getIncomeManageDetails(params) {
        return get(`/finance/gathering-management/findByCode`, params);
    },
    // 查询其他收入|支出 编辑
    getOtherPayDetailsForEdit(id) {
        return get(`/income/expenses-other/edit/${id}`);
    },
    // 删除其他收入|支出
    delOther(id) {
        return get(`/income/expenses-other/delete/${id}`);
    },
    // 直接收款 保存
    saveDirectCollection(params) {
        return postJson(`/ca/direct-collection/save`, params);
    },
    // 直接收款 查看详情
    getDirectCollectionDetails(id) {
        return get(`/ca/direct-collection/detail/${id}`);
    },

    /**
     * ------------------------------ 预付款 ------------------------------
     */
    // 预付款列表
    getAccountsList(params) {
        return postJson(`/ca/imprest/table`, params);
    },
    // 冲销预付款 所需数据
    getAccountsDetails(id) {
        return get(`/ca/imprest/charge-against-imprest/${id}`);
    },
    // 冲销预付款详情
    getWriteoffAccountsDetails(id) {
        return get(`/ca/imprest/detail/${id}`);
    },
    // 冲销应付款
    saveAccountsDetails(params) {
        return postJson(`/ca/imprest/save`, params);
    },

};

export default contractManageApi;
